import { get } from 'lodash';
import Cookie from 'js-cookie';
import { ELSURLHelper } from '@els/els-ui-common-react';
import { AppLinkData } from '../../apis/eols-app-link/eols-app-link.dtos';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { AppLinkCookie } from './app-link-redirect.constants';
import { ServerConstants } from '../../components/app/server.constants';
import { ELSCommonConfig } from '../../components/els.components';
import { ActiveSyllabusItemTypeDto, SyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';

const getUpdatedAssignmentIdsFromAppLinkData = (appLinkData: AppLinkData): string[] | number[] => {
  return get(appLinkData, 'returnPostBody.assignmentIds', []);
};

export const shouldConnectAssignmentToSyllabusItem = (appLinkData: AppLinkData): boolean => {
  if (appLinkData.action !== AppAction.ASSIGNMENT_CREATE) {
    return false;
  }
  const assignmentIds = getUpdatedAssignmentIdsFromAppLinkData(appLinkData);
  const syllabusItemId = get(appLinkData, 'outPostBody.syllabusItemId', false);
  return Boolean(assignmentIds.length === 1 && syllabusItemId);
};

export const shouldCreateNewSyllabusItemsForNewAssignments = (appLinkData: AppLinkData): boolean => {
  if (appLinkData.action !== AppAction.ASSIGNMENT_CREATE) {
    return false;
  }
  const assignmentIds = getUpdatedAssignmentIdsFromAppLinkData(appLinkData);
  const parentSyllabusItemId = get(appLinkData, 'outPostBody.parentSyllabusItemId', false);
  const syllabusItemId = get(appLinkData, 'outPostBody.syllabusItemId', false);
  return Boolean(assignmentIds.length >= 1 && !syllabusItemId && parentSyllabusItemId);
};

export const shouldShowAssignmentUpdatedToast = (appLinkData: AppLinkData): boolean => {
  // This is tech debt that should be cleaned up
  // EAQ should not be expecting us to treat preview actions like edits
  const isEAQEdit = appLinkData.targetApp === Application.EAQ && appLinkData.action === AppAction.ASSIGNMENT_PREVIEW;
  if (appLinkData.action === AppAction.ASSIGNMENT_EDIT || isEAQEdit) {
    const assignmentIds = get(appLinkData, 'returnPostBody.assignmentIds', []);
    return assignmentIds.length > 0;
  }
  return false;
};

export const removeCookies = () => {
  const domain = ServerConstants[ELSCommonConfig.appProfile].cookiesDomain;
  Cookie.remove(AppLinkCookie.X_LINK_ID, { path: '/', domain });
  Cookie.remove(AppLinkCookie.X_TOKEN, { path: '/', domain });
};

export const pickCookies = (): {
  token: string;
  linkId: string;
} => {

  const tokenFromUrl = ELSURLHelper.getParameterByName('token');
  const linkIdFromUrl = ELSURLHelper.getParameterByName('linkId');

  if (tokenFromUrl && linkIdFromUrl) {

    removeCookies();

    return {
      token: tokenFromUrl,
      linkId: linkIdFromUrl
    };
  }

  const token = Cookie.get(AppLinkCookie.X_TOKEN);
  const linkId = Cookie.get(AppLinkCookie.X_LINK_ID);

  removeCookies();

  return { token, linkId };
};

export const mapSyllabusItemTypeToApp: Partial<Record<SyllabusItemTypeDto, Application>> = {
  [ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON]: Application.KNOWLEDGECARD,
  [ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ]: Application.EAQ,
  [ActiveSyllabusItemTypeDto.SHERPATH_LESSON]: Application.SHERPATH,
  [ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS]: Application.SHERPATH,
  [ActiveSyllabusItemTypeDto.SHERPATH_SKILL]: Application.SHERPATH,
  [ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY]: Application.SIMSNG,
  [ActiveSyllabusItemTypeDto.SIMCHART_CHART]: Application.SIMSNG,
  [ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT]: Application.SHADOW_HEALTH,
  [ActiveSyllabusItemTypeDto.ASSESSMENT_BUILDER_ASSIGNMENT]: Application.EAB,
  [ActiveSyllabusItemTypeDto.INTERACTIVE_REVIEW]: Application.NHE_ASSESSMENT_PLAYER,
};
