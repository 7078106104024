import moment, {
  ISO_8601,
  Moment,
  MomentFormatSpecification
} from 'moment';
import momentTimezone from 'moment-timezone';
import {
  isArray,
  isEmpty,
  isNil,
  isString
} from 'lodash';
import messagesDE from '../translations/de';
import messagesEN from '../translations/en';
import { DATE_FORMAT, MIN_YEAR } from '../constants/date.constants';
import { ELSDropDownOption } from '../components/els.components';
import {
  EvolveProductDto,
  EvolveProductTypeKey
} from '../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { Application } from '../apis/eols-app-link/eols-app-link.constants';
import { getEntitlements } from '../pages/course-switcher/course-switcher.utilities';
import { CourseSectionDto } from '../apis/eols-course-crud/eols-course-crud.dtos';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from './featureFlag.utilities';
import { FEATURE_FLAG } from '../apis/eols-features-api/eols-features-api.constants';
import { FeatureFlagsGroupedDto } from '../apis/eols-features-api/eols-features-api.dtos';
import {
  isInstructor,
  truncateTitle
} from './common.utilities';
import { SyllabusTreeMapItem } from '../pages/course-builder/courseBuilder.models';
import { SyllabusItemDto } from '../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../pages/course-plan/syllabus.constants';

export const formatDate = (str: string) => moment(str).format(DATE_FORMAT);

export const parseDate = (str: string) => {
  const valid = moment(str, DATE_FORMAT, true).isValid();
  return valid ? moment(str, DATE_FORMAT, true).toDate() : undefined;
};

export const getLocalMomentInsFromServicesUTC = (date: string, format?: MomentFormatSpecification): Moment => {
  return moment.utc(date, format).local();
};

export const getLocalDateFromServicesUTC = (date: string): Date => {
  return getLocalMomentInsFromServicesUTC(date).toDate();
};

export const getWindowMode = () => {
  const { innerWidth } = window;
  const mode = { mobile: false, tablet: false, desktop: false, wide: false };
  if (innerWidth < 600) {
    return { ...mode, mobile: true };
  }
  if (innerWidth >= 600 && innerWidth < 900) {
    return { ...mode, tablet: true };
  }
  if (innerWidth >= 900 && innerWidth < 1200) {
    return { ...mode, desktop: true };
  }
  if (innerWidth >= 1200) {
    return { ...mode, wide: true };
  }
  return mode;
};

export const getTopPosition = () => window.pageYOffset || document.documentElement.scrollTop;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCleanSearchParamsFromObject = (searchParams: Record<string, any>): URLSearchParams => {
  const _searchParams = new URLSearchParams();
  Object.keys(searchParams).forEach(key => {
    const val = searchParams[key];
    if (isNil(val)) {
      return;
    }
    if (isArray(val) && !val.length) {
      return;
    }
    if (isString(val)) {
      _searchParams.append(key.toString(), val);
    } else if (isArray(val)) {
      val.forEach(valItem => {
        if (!isNil(valItem)) {
          _searchParams.append(key.toString(), valItem.toString());
        }
      });
    } else {
      _searchParams.append(key.toString(), JSON.stringify(val));
    }
  });
  return _searchParams;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addSearchParams = (url: string, searchParams: Record<string, any>): string => {
  const urlSearchParams = getCleanSearchParamsFromObject(searchParams);
  return `${url}?${urlSearchParams.toString()}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteSearchParams = (url: string, existingSearchParams: Record<string, any>, deletingParams: string[]): string => {
  const urlSearchParams = getCleanSearchParamsFromObject(existingSearchParams);
  deletingParams.forEach(param => urlSearchParams.has(param) && urlSearchParams.delete(param));
  if (isEmpty(urlSearchParams.toString())) {
    return url;
  }
  return `${url}?${urlSearchParams.toString()}`;
};

export const isValidDate = (str: string | Date, format: MomentFormatSpecification = [DATE_FORMAT, ISO_8601], strict = true) => {
  return moment(str, format, strict).isValid();
};

export const isValidDateEditor = (str: string | Date, format: MomentFormatSpecification = [DATE_FORMAT, ISO_8601], strict = true) => {
  const date = moment(str, format, strict);
  if (!date.isValid()) {
    return false;
  }
  return date.year() >= MIN_YEAR;
};

/**
 * There are 2 fields in DateTimeComponent: DateComponent and TimeComponent
 * originalDateInput is only bubbled up when we change value in DateComponent
 * originalDateInput is string while value is the unstrictly converted Date so have to validate originalDateInput to ensure correct format
 *
 */
export const validateDateTimeChange = (value: Date, originalDateInput: string): boolean => {
  if (originalDateInput === undefined) {
    return isValidDate(value);
  }
  return isValidDate(originalDateInput);
};

export const messages: Record<string, Record<string, string>> = {
  de: messagesDE,
  en: messagesEN
};

export const getLanguage = () => {
  const defaultLanguage = 'en';
  let browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language;
  const supporting = ['en', 'de'];
  if (supporting.includes(browserLanguage)) {
    return browserLanguage;
  }
  browserLanguage = browserLanguage.substring(0, 2);

  if (supporting.includes(browserLanguage)) {
    return browserLanguage;
  }

  return defaultLanguage;
};

export const SELECT_OPTION: ELSDropDownOption = {
  name: '--Select--',
  value: ''
};

export const getPrimaryEvolveProductType = (evolveProducts: EvolveProductDto[]): EvolveProductTypeKey => {
  const sortOrderByProductTypeKey: EvolveProductTypeKey[] = [
    EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED,
    EvolveProductTypeKey.SHERPATH_IA,
    EvolveProductTypeKey.SIMULATION_SIM_CHART_NG,
    EvolveProductTypeKey.ELSEVIER_ASSESSMENT_BUILDER,
    EvolveProductTypeKey.CLINICALSKILLS_IA,
    EvolveProductTypeKey.SIMULATION_SLS_IA,
  ];

  if (!evolveProducts || !evolveProducts.length) {
    return EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED;
  }

  const sortedEvolveProducts = [...evolveProducts].sort((product1, product2) => {
    return sortOrderByProductTypeKey.indexOf(product1.productTypeKey) - sortOrderByProductTypeKey.indexOf(product2.productTypeKey);
  });

  if (sortedEvolveProducts.length) {
    return sortedEvolveProducts[0].productTypeKey;
  }

  return EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED;
};

export const getApplicationContext = (evolveProducts: EvolveProductDto[]): Application => {
  const evolveProductTypeToApplicationMap: Partial<Record<EvolveProductTypeKey, Application>> = {
    [EvolveProductTypeKey.SHERPATH_IA]: Application.SHER_EVOL,
    [EvolveProductTypeKey.SIMULATION_SIM_CHART_NG]: Application.SIMSNG,
    [EvolveProductTypeKey.ELSEVIER_ASSESSMENT_BUILDER]: Application.EAB,
  };

  const primaryProductType = getPrimaryEvolveProductType(evolveProducts);
  const application = evolveProductTypeToApplicationMap[primaryProductType];

  if (!application) {
    return Application.SHER_EVOL;
  }
  return application;
};

export const getWordMark = (evolveProducts: EvolveProductDto[]): string => {
  const primaryEvolveProductType = getPrimaryEvolveProductType(evolveProducts);
  if (primaryEvolveProductType === EvolveProductTypeKey.SIMULATION_SIM_CHART_NG) {
    return 'SimChart';
  }
  if (primaryEvolveProductType === EvolveProductTypeKey.ELSEVIER_ASSESSMENT_BUILDER) {
    return 'Assessment Builder';
  }
  if (primaryEvolveProductType === EvolveProductTypeKey.CLINICALSKILLS_IA) {
    return 'Clinical Skills';
  }
  if (primaryEvolveProductType === EvolveProductTypeKey.SIMULATION_SLS_IA) {
    return 'Simulation Learning System';
  }
  return 'Sherpath';
};

const regexAnyDigit = '\\d';

export const getClientZoneAbbr = () => {
  const clientZone = momentTimezone.tz.guess();
  const currentZoneAbbr = momentTimezone.tz(momentTimezone(), clientZone).zoneAbbr();
  const doesContainDigit = new RegExp(regexAnyDigit).test(currentZoneAbbr);
  if (doesContainDigit) {
    return clientZone;
  }
  return currentZoneAbbr;
};

export const clientTimeZone = momentTimezone.tz.guess();

export const clientZoneAbbr = getClientZoneAbbr();

export const convertIsbnsToNormalizedString = (isbns: string[]): string => {
  if (!isbns || !isbns.length) {
    return '';
  }
  return [...isbns].sort().join(',');
};

export const convertIsbnsForDeepLinks = (isbns: string[]): string => {
  if (!isbns || !isbns.length) {
    return '';
  }
  return isbns.join(';');
};

export const hasVantage = (course: CourseSectionDto) => {
  if (!course || !course.entitlements || !course.entitlements.length) {
    return false;
  }
  return course.entitlements.some((entitlement) => {
    return entitlement.evolveProductTypeKey === EvolveProductTypeKey.SHERPATH_VANTAGE_IA;
  });
};

export const useVantageTrainingLink = (props: {
  course: CourseSectionDto;
  userRole: string;
  courseSectionId: string;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
}) => {

  const {
    featureFlagsGrouped,
    courseSectionId,
    userRole,
    course
  } = props;

  const _hasVantage = hasVantage(course);
  if (!_hasVantage) {
    return false;
  }

  if (isInstructor(userRole)) {
    return true;
  }

  return featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.IS_ENABLE_STU_VANTAGE_TRAINING_LINK,
    courseSectionId
  ) : false;
};

export const getIsbnsForVantageCourse = (tokenIsbns: string, course: CourseSectionDto): string[] => {
  if (!course || !course.entitlements || !course.entitlements.length || !tokenIsbns) {
    return null;
  }

  const tokenIsbnsArr = tokenIsbns.split(',');

  if (!tokenIsbnsArr) {
    return null;
  }

  const entitlements = getEntitlements(course);

  if (!entitlements) {
    return null;
  }

  return tokenIsbnsArr.reduce((acc, isbn) => {

    const entitlement = entitlements.find((_entitlement) => {
      return _entitlement.entitlement.isbn === isbn;
    });

    if (!entitlement) {
      return acc;
    }

    if (entitlement.evolveProduct.productTypeKey !== EvolveProductTypeKey.SHERPATH_VANTAGE_IA) {
      return [
        ...acc,
        isbn
      ];
    }
    return entitlement.evolveProduct.realVantageComponent.reduce((_acc, component) => {
      if (component.productTypeKey.includes('_nss')) {
        return _acc;
      }
      return [
        ..._acc,
        component.isbn
      ];
    }, acc);
  }, []);

};

export const truncateSyllabusItemTitles = (syllabusTreeMapItem: SyllabusTreeMapItem): SyllabusItemDto => {
  return {
    ...syllabusTreeMapItem.syllabusItem,
    title: truncateTitle(syllabusTreeMapItem.syllabusItem.title, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
    subtitle: truncateTitle(syllabusTreeMapItem.syllabusItem.subtitle, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH)
  };
};

export const isInIframe = (): boolean => {
  return window.self !== window.top;
};

export const toPascalCaseWithSpaces = (input: string): string => {
  return input
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
