import {
  NavItemConfig,
  NavigationSection
} from './navigation.models';
import { RoutePath } from '../app/app.constants';

export enum NavItemCategory {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum NavItem {
  MY_COURSE_PLAN = 'MY_COURSE_PLAN',
  ALL_COURSES_OVERVIEW = 'ALL_COURSES_OVERVIEW',
  COURSE_SETTINGS = 'COURSE_SETTINGS',
  HELP = 'HELP',
  PERFORMANCE_AND_GRADES = 'PERFORMANCE_AND_GRADES',
  RESOURCES_AND_EBOOKS = 'RESOURCES_AND_EBOOKS',
  TRAINING = 'TRAINING'
}

export const NavItemTextMap: Record<NavItem, string> = {
  [NavItem.MY_COURSE_PLAN]: 'My Course Plan',
  [NavItem.ALL_COURSES_OVERVIEW]: 'All Courses Overview',
  [NavItem.COURSE_SETTINGS]: 'Course Settings',
  [NavItem.HELP]: 'Help',
  [NavItem.PERFORMANCE_AND_GRADES]: 'Performance & Grades',
  [NavItem.RESOURCES_AND_EBOOKS]: 'Resources & eBooks',
  [NavItem.TRAINING]: 'Training'
};

export const NavItemConfigMap: Record<NavItem, NavItemConfig> = {
  [NavItem.MY_COURSE_PLAN]: {
    category: NavItemCategory.PRIMARY,
    isInternal: true,
    disabled: false,
    navItem: NavItem.MY_COURSE_PLAN,
    path: RoutePath.COURSE,
    text: NavItemTextMap[NavItem.MY_COURSE_PLAN],
    showOnMobile: true,
    isTooltipItem: false,
    tooltipId: null,
  },
  [NavItem.ALL_COURSES_OVERVIEW]: {
    category: NavItemCategory.PRIMARY,
    isInternal: true,
    disabled: false,
    navItem: NavItem.ALL_COURSES_OVERVIEW,
    path: RoutePath.ASSIGNMENT_OVERVIEW,
    text: NavItemTextMap[NavItem.ALL_COURSES_OVERVIEW],
    showOnMobile: true,
    isTooltipItem: false,
    tooltipId: null
  },
  [NavItem.PERFORMANCE_AND_GRADES]: {
    category: NavItemCategory.PRIMARY,
    isInternal: false,
    disabled: false,
    navItem: NavItem.PERFORMANCE_AND_GRADES,
    path: null,
    text: NavItemTextMap[NavItem.PERFORMANCE_AND_GRADES],
    showOnMobile: true,
    isTooltipItem: false,
    tooltipId: null
  },
  [NavItem.RESOURCES_AND_EBOOKS]: {
    category: NavItemCategory.PRIMARY,
    isInternal: true,
    disabled: false,
    navItem: NavItem.RESOURCES_AND_EBOOKS,
    path: RoutePath.CATALOG,
    text: NavItemTextMap[NavItem.RESOURCES_AND_EBOOKS],
    showOnMobile: true,
    isTooltipItem: false,
    tooltipId: null
  },
  [NavItem.COURSE_SETTINGS]: {
    category: NavItemCategory.PRIMARY,
    disabled: false,
    isInternal: true,
    navItem: NavItem.COURSE_SETTINGS,
    path: RoutePath.SETTINGS,
    text: NavItemTextMap[NavItem.COURSE_SETTINGS],
    showOnMobile: true,
    isTooltipItem: false,
    tooltipId: null
  },
  [NavItem.HELP]: {
    category: NavItemCategory.PRIMARY,
    disabled: false,
    isInternal: true,
    navItem: NavItem.HELP,
    path: RoutePath.HELP,
    text: NavItemTextMap[NavItem.HELP],
    showOnMobile: true,
    isTooltipItem: false,
    tooltipId: null
  },
  [NavItem.TRAINING]: {
    category: NavItemCategory.PRIMARY,
    disabled: false,
    isInternal: true,
    navItem: NavItem.TRAINING,
    path: null,
    text: NavItemTextMap[NavItem.TRAINING],
    showOnMobile: false,
    isTooltipItem: true,
    tooltipId: 'header-nav-training-tooltip'
  }
};

export const instructorNavItems: NavigationSection[] = [
  {
    category: NavItemCategory.PRIMARY,
    items: [
      NavItemConfigMap.MY_COURSE_PLAN,
      NavItemConfigMap.RESOURCES_AND_EBOOKS,
      NavItemConfigMap.PERFORMANCE_AND_GRADES,
      NavItemConfigMap.COURSE_SETTINGS,
      NavItemConfigMap.TRAINING,
      NavItemConfigMap.HELP,
    ]
  }
];

export const studentNavItems: NavigationSection[] = [
  {
    category: NavItemCategory.PRIMARY,
    items: [
      NavItemConfigMap.MY_COURSE_PLAN,
      NavItemConfigMap.ALL_COURSES_OVERVIEW,
      NavItemConfigMap.RESOURCES_AND_EBOOKS,
      NavItemConfigMap.PERFORMANCE_AND_GRADES,
      NavItemConfigMap.TRAINING,
      NavItemConfigMap.HELP,
    ]
  }
];
