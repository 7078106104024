export interface LiteCourseSectionDto {
  copied: boolean;
  courseActive: boolean;
  courseId: string;
  courseName: string;
  duration: number;
  externalId: string;
  id: number;
  locked: boolean;
  sectionName: string;
}

export enum CourseMigrationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  COMPLETED_ALREADY_MIGRATED = 'COMPLETED_ALREADY_MIGRATED',
  COMPLETED_NO_SCHEDULES = 'COMPLETED_NO_SCHEDULES',
  FAILED = 'FAILED',
  INVALID_COURSE = 'INVALID_COURSE'
}

export interface MigrationStatusDTO {
  courseSectionId: number;
  createdAt: string;
  createBy: number;
  id: number;
  status: CourseMigrationStatus;
  updatedAt: string;
  updatedBy: number;
}
