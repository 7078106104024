import { AxiosResponse } from 'axios';
import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
import {
  LiteCourseSectionDto,
  MigrationStatusDTO
} from './eols-course-management-service.dtos';

export const fetchCourseSection = (selectedCourseSectionId: string): Promise<AxiosResponse<LiteCourseSectionDto>> => {
  return eolsBaseApi.get<LiteCourseSectionDto>(`/coursePlanning/courseSections/${selectedCourseSectionId}`);
};

export const fetchCoursewareMigrationStatus = (courseSectionId: string): Promise<MigrationStatusDTO> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/sherpath/course-management/courseSections/${courseSectionId}/migration/status`);
  });
};

export const updateCourseSection = (courseSectionId: string, updatedCourseInfo: Partial<LiteCourseSectionDto>, etag: string): Promise<LiteCourseSectionDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put<LiteCourseSectionDto>(`/coursePlanning/courseSections/${courseSectionId}`, {
      data: updatedCourseInfo,
      headers: {
        'If-Match': etag
      }
    });
  });
};
